<template>
    <div class="apaympro-container">
        <div class="apaympro-color-bar"></div>
        <div id="page-okay" class="apaympro-wrapper animated fadeIn">
            <div id="payment-page-pay" class="apaympro-page">
                <div class="apaympro-header">
                    <div class="mt-5">
                        <img
                            src="../assets/refusico.gif"
                            alt="icon page not found - page payment apaym pro"
                            width="200"
                            height="200"
                            style="width: 200px; height: 100%"
                        />
                    </div>
                    <div class="page-info mt-3 mb-3">
                        <h3
                            id="page-name"
                            style="
                                font-size: 20px;
                                font-weight: normal;
                                margin: 20px auto 0px;
                                width: 80%;
                                line-height: 1.3;
                            "
                        >Page non trouvée !</h3>
                    </div>
                    <div class="element-wrap element-wrap-for-submit mt-5 mb-5">
                        <a
                            type="button"
                            id="pay-btn"
                            href="https://pay.apaym.com"
                            target="_blank"
                            class="btn btn-outline-primary btn-block"
                        >Retour</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
        }
    },
    methods: {
        onCancel() {
            console.log('Loader dismiss')
        },
    },
    computed: {
    },
    mounted() {
    },

}

</script>

<style scoped>
.box-wrapper {
    /* flex: 1 0 auto; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-content {
    max-width: 100%;
    /* flex-basis: 540px; */
    text-align: center;
}

.display-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 200px; */
}
</style>